const Liner = {};

Liner.scroll = {
  init: function(){
    let disableMenu = false;
    let mobile;

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      mobile = true;
    }
    console.log(mobile);
    $('a[href*="#"]:not([href="#"])').click(function() {
      if (mobile) {
        $('.js-menu, .js-menu-btn, .js-logo').removeClass('active');
      }
      disableMenu = true;
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        let target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top
          }, {
            duration: 800,
            complete: function(){
              disableMenu = false;
            }
          });
          return false;
        }
      }
    });

		let items = $('.js-anchor');
		let displayHeight = window.innerHeight;
    let top;
    let lastScroll = 0;

		$(document).on('scroll', function(event){
      let screenPos = $(window).scrollTop();
      if (!mobile) {
        if(screenPos < lastScroll  && screenPos > 100 && disableMenu === false) {
          $('.js-menu, .js-menu-btn, .js-logo').addClass('active');
        }else {
          $('.js-menu, .js-menu-btn, .js-logo').removeClass('active');
        }
        lastScroll = screenPos;
      }
		});
  }
};

Liner.parallax = {
  init: function () {
    // $('.con-section').parallax({imageSrc: '/images/con-bg.jpg'});
  }
}

Liner.menu = {
  init: function(){
    $('.js-menu-btn').on('click', function(){
      let t = $(this);
      t.toggleClass('active');
      $('.js-menu').toggleClass('active');
      $('.js-logo').toggleClass('active');
    });
  }
}

Liner.popup = {
  init: function () {
    $('.js-open-popup').on('click', function (e) {
      e.preventDefault();
      let t = $(this);
      let id = t.attr('data-open');
      console.log($("[data-popup="+ id +"]"))
      $("[data-popup="+ id +"]").fadeToggle();
    });
    $('input').blur(function() {
      if ($(this).val()) {
        $(this).addClass('used');
      }
      else {
        $(this).removeClass('used');
      }
    });
  }
}

$(document).ready(function() {
  Liner.popup.init();
  Liner.scroll.init();
  Liner.menu.init();
  Liner.parallax.init();
});
